import Bread from "../components/bread";
import Footer from "../components/footer";
import { Row, Col, Button } from 'antd';
import { Link } from 'react-router-dom'

export default function InvestorRelation() {
  return (
    <main>
      <Bread />

      <div className="investor-relation">
        <div className="investor-content">
          <Row className="item-box">
            <Col md={18} sm={24}>
              <h2 className="title">Annual Report - March 2020</h2>
            </Col>
            <Col md={6} sm={24}>
              <a href="https://miepay-sg-public.oss-ap-southeast-1.aliyuncs.com/pdf/MIEPAY-AR-March-2020.pdf" target="_blank">
                <Button className="btn" type="primary" size="large">Download</Button>
              </a>
            </Col>
          </Row>
          <Row className="item-box">
            <Col md={18} sm={24}>
              <h2 className="title">Annual General Meeting 2020</h2>
            </Col>
            <Col md={6} sm={24}>
              <a href="https://miepay-sg-public.oss-ap-southeast-1.aliyuncs.com/pdf/MIEPAY-AGM-NOTICE-2020.pdf" target="_blank">
                <Button className="btn" type="primary" size="large">Download</Button>
              </a>
            </Col>
          </Row>
          <Row className="item-box">
            <Col md={18} sm={24}>
              <h2 className="title">Prospectus</h2>
            </Col>
            <Col md={6} sm={24}>
              <Link to="/prospectus-disclaimer">
                <Button className="btn" type="primary" size="large">Download</Button>
              </Link>
            </Col>
          </Row>
          <Row className="item-box">
            <Col md={18} sm={24}>
              <h2 className="title">Corporate Governance</h2>
            </Col>
            <Col md={6} sm={24}>
              <Link to="/corporate-governance">
                <Button className="btn" type="primary" size="large">GO</Button>
              </Link>
            </Col>
          </Row>
          <Row className="item-box">
            <Col md={18} sm={24}>
              <h2 className="title">Recent changes to the Corporations Act 2001</h2>
            </Col>
            <Col md={6} sm={24}>
              <a href="https://miepay-sg-public.oss-ap-southeast-1.aliyuncs.com/pdf/Notice%20of%20Change%20of%20Shareholder%20Communications.pdf" target="_blank">
                <Button className="btn" type="primary" size="large">Download</Button>
              </a>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </main>
  );
}
